<template>
  <div class="carousel-container">
    <div class="card-carousel-wrapper">
      <!-- Nav Left -->
      <div
        class="card-carousel-nav-left"
        :class="{ 'hidden': cards && cards.length <= colItems }"
        :disabled="atHeadOfList"
        @click="moveCarousel(-1)">
        <img
          class="nav-left-img"
          :src="arrowLeft"
          alt="Carousel Nav Left"
        />
      </div>

      <!-- Card -->
      <div class="card-carousel">
        <div
          class="card-carousel-cards"
          :style="{transform: 'translateX(' + currentOffset + 'px)'}">
          <VideoCarouselCard
            class="card-carousel-card"
            v-for="(card, index) in cards"
            :key="index"
            :card="card"
            :columnItems="colItems"
            :cardCarouselWidth="cardCarouselWidth"
            @selectedVid="$emit('selectedVid', card)"
          />
        </div>
      </div>

      <!-- Nav Right -->
      <div
        class="card-carousel-nav-right"
        :class="{ 'hidden': cards && cards.length <= colItems }"
        :disabled="atEndOfList"
        @click="moveCarousel(1)">
        <img
          class="nav-right-img"
          :src="arrowRight"
          alt="Carousel Nav Right"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import BreakpointMixin from '@/mixins/BreakpointMixin';

  export default {
    mixins : [BreakpointMixin],
    props  : {
      cards : {
        type     : Array,
        required : true,
      },
    },
    data() {
      return {
        currentOffset     : 0,
        cardCarouselWidth : 0,
      }
    },
    computed : {
      atEndOfList() {
        return this.currentOffset <= ((this.paginationFactor * -1) *
          (this.cards.length - this.colItems));
      },
      atHeadOfList() {
        return this.currentOffset >= 0;
      },
      paginationFactor() {
        return Math.round(this.cardCarouselWidth / this.colItems);
      },
      cardWidthStyle() {
        const gap = 18; // left and right margin 9px ea
        return (this.cardCarouselWidth / this.colItems) - gap;
      },
      cardHeightStyle() {
        //16:10 ratio
        return this.cardWidthStyle ? this.cardWidthStyle * 0.625 : 0;
      },
      colItems() {
        let size = 1;

        if (this.windowWidth >= 992)
          size = 3; //this.cards.length < 3 ? this.cards.length : 3;
        else if (this.windowWidth >= 768)
          size = this.cards.length < 3 ? this.cards.length : 2;
        else if (this.windowWidth >= 576)
          size = this.cards.length < 3 ? this.cards.length : 1;
        
        return size;
      },
      arrowLeft() {
        return require('../../assets/images/carousel/yellow-arrow-left.png');
      },
      arrowRight() {
        return require('../../assets/images/carousel/yellow-arrow-right.png');
      },
    },
    components : {
      'VideoCarouselCard' : () => import('./VideoCarouselCard'),
    },
    methods : {

      /**
       * Move Carousel either left or right
       *
       * @param {integer} direction either -1 (left) or 1 (right)
       */
      moveCarousel(direction) {
        // Find a more elegant way to express the :style.
        //  consider using props to make it truly generic
        if (direction === 1 && !this.atEndOfList)
          this.currentOffset -= this.paginationFactor;

        else if (direction === -1 && !this.atHeadOfList)
          this.currentOffset += this.paginationFactor;
      },
    },
    mounted() {
      this.$nextTick(function() {
        this.cardCarouselWidth =
          this.$el.getElementsByClassName('card-carousel-cards')[0].clientWidth;
      });
    },
    updated() {
      this.cardCarouselWidth =
        this.$el.getElementsByClassName('card-carousel-cards')[0].clientWidth;
      this.getWindowWidth();
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .card-carousel-wrapper {
    display: flex;
    margin-bottom: 40px;
    align-items: center;
    justify-content: center;
  }

  .card-carousel {
    display: flex;
    width: 100%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
  }

  .card-carousel-nav-left,
  .card-carousel-nav-right {
    display: inline-block;
    cursor: pointer;
    box-sizing: border-box;
  }

  .card-carousel-nav-left {
    margin-left: 8px;
  }
  .card-carousel-nav-right {
    margin: 0 8px 0;
  }

  .card-carousel-nav-left.hidden,
  .card-carousel-nav-right.hidden {
    display: none;
    visibility: inherit;
  }

  .card-carousel-cards {
    display: flex;
    width: 100%;
    transform: translatex(0px);
    transition: transform 150ms ease-out;
  }

  .card-carousel-card {
    z-index: 3;
    margin: 0 9px !important;
    border-radius: 16px;
    cursor: pointer;
  }

  .nav-left-img, .nav-right-img {
    width: 1rem;
  }
</style>